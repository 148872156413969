import React, { useState, useEffect } from "react";
import { IoCloseOutline } from "@react-icons/all-files/io5/IoCloseOutline";

import dynamic from "next/dynamic";

import { Icon } from "../../icons/icons";
import { useRouter } from "next/router";
import { useTranslation } from "../../context/TranslationContext";
const MenuContent = dynamic(() => import("./MenuContent"));

const Menu = ({ headerContent }) => {
  const { closeUpper, menuUpper } = useTranslation();
  const router = useRouter();
  const [MenuIsOpen, setMenuIsOpen] = useState(0);

  function ToggleMenu(a = null) {
    setMenuIsOpen(!MenuIsOpen);
  }
  useEffect(() => {
    setMenuIsOpen(false);
  }, [router.query.slug]);

  return (
    <>
      <button
        type="button"
        className="hidden w-24 h-full ml-auto bg-brand-white text-brand-black lg:flex flex-col items-center justify-center gap-y-2 border-r border-gray bg-opacity-50	"
        onClick={ToggleMenu}
      >
        {MenuIsOpen ? (
          <IoCloseOutline size={36} className={"-mb-1"} />
        ) : (
          <Icon name={"Bar"}></Icon>
        )}
        <span className="text-xxs font-bold tracking-1">
          {MenuIsOpen ? closeUpper : menuUpper}
        </span>
      </button>
      {headerContent && (
        <MenuContent data={headerContent} MenuIsOpen={MenuIsOpen} />
      )}
    </>
  );
};

export default Menu;
